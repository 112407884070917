import {Routers}  from "./routers/Routers";

function App() {

  return (
    <Routers/>
  );
}

export default App;
